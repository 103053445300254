<template>
  <div class="about">
    <div class="banner_container about_banner border_text_box">
      <div class="border_text">About Us</div>
      <h1 class="border_text_title">关于我们</h1>
    </div>
    <div class="bread_crumbs container_width">
      <span>您的位置：首页 > </span>
      <span class="current">关于我们</span>
    </div>
    <div class="company_description_container border_text_box container_width">
      <div class="border_text">Introduction</div>
      <div class="border_text_title">贤牛集团简介</div>
      <div class="description">武汉贤牛科技有限公司2019年2月22日,成立于武汉洪山区，公司办公地点环境优美。且交通便利。武汉贤牛科技有限公司，以推动效果营销行业发展为己任,专注致力于信息咨询、效果营销、SAS软件服务、跨境电商、进出口贸易,以及各类跨境电商服务解决方案领域,是一家创新型网络科技企业。</div>
    </div>
    <div class="development_plan_container container_width">
      <div class="left" v-bind:class="{ fadeinleft: flag }">
        <h3>企业发展战略</h3>
        <div>以跨境电商为主业依托，大力拓展上下游相关产业，并逐步将每个产业发展成为行业领先产业，具备条件的独立上市，利用五至八年时间，将整个产业集团打造成国内百强企业。</div>
        <div class="a_colorless_button"><a href="javascript:;">阅读更多</a></div>
      </div>
      <div class="right" v-bind:class="{ fadeinright: flag }">
        <swiper :options="PlanSwiperOption" class="plan_swiper">
          <swiper-slide><img src="../assets/imgs/aboutus/plan_swiper_1.webp" alt=""></swiper-slide>
          <swiper-slide><img src="../assets/imgs/aboutus/plan_swiper_2.png" alt=""></swiper-slide>
          <swiper-slide><img src="../assets/imgs/aboutus/plan_swiper_3.png" alt=""></swiper-slide>
        </swiper>
        <div class="plan-swiper-pagination planswp"></div>
      </div>
    </div>
    <div class="company_culture_container container_width">
      <div class="left" v-bind:class="{ fadeinleft: flag1 }">
        <swiper :options="CultureSwiperOption" class="culture_swiper">
          <swiper-slide><img src="../assets/imgs/aboutus/culture_swiper_1.webp" alt=""></swiper-slide>
          <swiper-slide><img src="../assets/imgs/aboutus/culture_swiper_2.png" alt=""></swiper-slide>
          <swiper-slide><img src="../assets/imgs/aboutus/culture_swiper_3.png" alt=""></swiper-slide>
        </swiper>
        <div class="culture-swiper-pagination cultureswp"></div>
      </div>
      <div class="right" v-bind:class="{ fadeinright: flag1 }">
          <div class="title"><img src="../assets/imgs/aboutus/company_culture_1.webp" alt=""> 企业文化</div>
          <div class="content center">企业善待员工员工心系企业员工和企业共同和谐成长。公司企业文化理念包括了公司的使命、愿景、核心价值观、廉洁文化理念等精神层面的文化总和，是公司价值理念、经营理念和未来发展目标的观念形态，更是公司的发展观、经营观、价值观等企业文化总和在企业风貌上的高度概括和集中反映。</div>
          <div class="title"><img src="../assets/imgs/aboutus/company_culture_2.webp" alt=""> 企业态度</div>
          <div class="content">企业态度我们相信，未来有无限种可能，而我们想 要做的就是将未来变成现实，用卓越实现最有 价值的那种可能！</div>
      </div>
    </div>
    <div class="about_team_container">
      <div class="about_team_box border_text_box container_width">
        <div class="border_text">About the team</div>
        <div class="border_text_title about_title">关于团队</div>
        <ul class="team_list_box">
          <li>
            <div class="box">
              <img src="../assets/imgs/aboutus/about_team_1.webp" alt="">
              <div class="team_introduction">
                <div>85</div>
                <div>家百强广告主</div>
              </div>
            </div>
          </li>
          <li>
            <div class="box">
              <img src="../assets/imgs/aboutus/about_team_2.webp" alt="">
              <div class="team_introduction">
                <div>11,00</div>
                <div>家客户 </div>
              </div>
            </div>
          </li>
          <li>
            <div class="box">
              <img src="../assets/imgs/aboutus/about_team_3.webp" alt="">
              <div class="team_introduction">
                <div>143</div>
                <div>个市场</div>
              </div>
            </div>
          </li>
          <li>
            <div class="box">
              <img src="../assets/imgs/aboutus/about_team_4.webp" alt="">
              <div class="team_introduction">
                <div>6,010</div>
                <div>名充满热情的员工</div>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
    <div class="our_services_container">
      <div class="our_services_box border_text_box container_width">
        <div class="border_text">Our Services</div>
        <div class="border_text_title">我们的服务</div>
        <div class="description">资深效果营销团队，对Affiliate行业十年深耕，精通各种类型流量，以及Offers效果观察，我们有自有网盟 Yoyu Media ,一手流量，业务包括，CPA CPL CPI CPS 等诸多类型。</div>
        <ul class="serverices_list_box">
          <li v-bind:class="{ service_card_in1: flag2 }">
            <div class="serverices_card_img img_1"></div>
            <div class="services_introduction">
              <div>创意</div>
              <div>我们在全球范围内提供为数字时代而打造的创意体验。</div>
            </div>
          </li>
          <li v-bind:class="{ service_card_in2: flag2 }">
            <div class="serverices_card_img img_2"></div>
            <div class="services_introduction">
              <div>媒介</div>
              <div>我们设计，创建和优化媒介体验，在消费者与品牌之间建立联系。</div>
            </div>
          </li>
          <li v-bind:class="{ service_card_in1: flag3 }">
            <div class="serverices_card_img img_3"></div>
            <div class="services_introduction">
              <div>客户体验管理（CXM）</div>
              <div>我们针对客户喜欢的定制体验开发数据驱动策略。</div>
            </div>
          </li>
          <li v-bind:class="{ service_card_in2: flag3 }">
            <div class="serverices_card_img img_4"></div>
            <div class="services_introduction">
              <div>全自动广告投放系统</div>
              <div>自动广告可提供独具创新的简便方式，让您通过内容获利。</div>
            </div>
          </li>
          <li v-bind:class="{ service_card_in1: flag4 }">
            <div class="serverices_card_img img_5"></div>
            <div class="services_introduction">
              <div>创意</div>
              <div>我们在全球范围内提供为数字时代而打造的创意体验。</div>
            </div>
          </li>
          <li v-bind:class="{ service_card_in2: flag4 }">
            <div class="serverices_card_img img_6"></div>
            <div class="services_introduction">
              <div>媒介</div>
              <div>我们设计，创建和优化媒介体验，在消费者与品牌之间建立联系。</div>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import {Swiper, SwiperSlide} from 'vue-awesome-swiper'
import 'swiper/css/swiper.css'
import '../assets/scss/animation.scss'
import '../assets/scss/communal.scss'
export default {
  name: "about",
  components: {
    Swiper,
    SwiperSlide,
  },
  data() {
    return {
      PlanSwiperOption: {
        loop: true,
        pagination: {
          el: '.plan-swiper-pagination.planswp',
          clickable: true,
        }
      },
      CultureSwiperOption: {
        loop: true,
        pagination: {
          el: '.culture-swiper-pagination.cultureswp',
          clickable: true,
        }
      },
      flag:false,
      flag1: false,
      flag2: false,
      flag3: false,
      flag4: false,
    }
  },
  mounted(){
      //获取页面可视化高度
      window.addEventListener("scroll",()=>{ 
      //获取页面滚动的高度
          let scrollTop = document.body.scrollTop || document.documentElement.scrollTop;
          if(scrollTop > 400){
              this.flag = true;
          }
          if(scrollTop > 1100){
              this.flag1 = true;
          }
          if(scrollTop > 2700){
              this.flag2 = true;
          }
          if(scrollTop > 2900){
              this.flag3 = true;
          }
          if(scrollTop > 3000){
              this.flag4 = true;
          }
      }) 
  },
}
</script>

<style lang="scss">
@import '../assets/scss/layout.scss';
.about {
  .about_banner {
        background: url('../assets/imgs/aboutus/banner.webp') 50% 50%;
        background-size: cover;
        .border_text_title {
          color: #fff
        }
  }
  .development_plan_container {
    @include flexrl();
    .left {
      width: 39%;
      opacity: 0;
      animation-fill-mode: both;
      h3 {
        margin-bottom: 60px;
        font-size: 48px;
        font-weight: bold;
        text-align: left;
        color: #161616;
        line-height: 48px;
      }
      div {
        margin-bottom: 80px;
        font-size: 16px;
        text-align: justify;
        color: #464949;
        line-height: 24px;
        letter-spacing: -0.03px;
      }
    }
    .right {
      width: 50%;
      opacity: 0;
      animation-fill-mode: both;
      .planswp {
        @include flexc();
        margin-top: 25px;
        .swiper-pagination-bullet {
          width: 11px;
          height: 11px;
          border: 1px solid #464949;
          background: none;
        }
        .swiper-pagination-bullet:not(:first-of-type):not(:last-of-type) {
          margin: 0 15px;
        }
        .swiper-pagination-bullet-active {
          background: #000;
        }
      }
      .plan_swiper {
        img {
          width: 100%;
        }
      }
    }
  }
  .company_culture_container {
    @include flexrl();
    margin: 120px auto 140px;
    .left {
      width: 49%;
      opacity: 0;
      animation-fill-mode: both;
      .cultureswp {
        @include flexrowleft();
        margin-top: 25px;
        .swiper-pagination-bullet {
          width: 11px;
          height: 11px;
          border: 1px solid #464949;
          background: none;
        }
        .swiper-pagination-bullet:not(:first-of-type):not(:last-of-type) {
          margin: 0 15px;
        }
        .swiper-pagination-bullet-active {
          background: #000;
        }
      }
      .culture_swiper {
        img {
          width: 100%;
        }
      }
    }
    .right {
      width: 39%;
      opacity: 0;
      padding: 47px 0;
      animation-fill-mode: both;
      .title {
        font-size: 24px;
        font-weight: bold;
        color: #161616;
        line-height: 24px;
        margin-bottom: 20px;
        display: flex;
        img {
          max-width: 24px;
          margin-right: 10px;
        }
      }
      .content {
        font-size: 16px;
        text-align: justify;
        color: #464949;
        line-height: 24px;
        letter-spacing: -0.03px;
      }
      .center {
        margin-bottom: 70px;
      }
    }
  }
  .about_team_container {
    padding: 80px 0 180px;
    background: url('../assets/imgs/aboutus/about_team_bg.webp') 50% 50%;
    background-size: cover;
    .about_team_box {
      .about_title {
        color: #fff;
      }
      .team_list_box {
        margin-top: 135px;
        @include flexrl();
        li {
          .box {
            @include flexrowleft();
            img {
              max-width: 84px;
              margin-right: 30px;
            }
            .team_introduction {
              text-align: left;
              width: 50%;
              div:first-child {
                font-size: 48px;
                font-weight: bold;
                color: #ffffff;
                line-height: 48px;
              }
              div:last-child {
                font-size: 20px;
                color: #ffffff;
                line-height: 20px;
              }
            }
          }
        }
      }
    }
  }
  .our_services_container {
    padding: 70px 0 30px;
    background: #f7f7f7;
    .our_services_box {
      .serverices_list_box {
        @include flexrl();
        flex-wrap: wrap;
        li {
          @include flexrowleft();
          opacity: 0;
          width: 50%;
          margin-bottom: 120px;
          animation-fill-mode: both;
          .serverices_card_img {
            width: 100px;
            height: 69px;
          }
          .img_1 {
            background: url('../assets/imgs/aboutus/our_services_1.webp') 50% 50%;
            background-size: 100px 70px;
            background-repeat:no-repeat;
          }
          .img_2 {
            background: url('../assets/imgs/aboutus/our_services_2.webp') 50% 50%;
            background-size: 100px 70px;
            background-repeat:no-repeat;
          }
          .img_3 {
            background: url('../assets/imgs/aboutus/our_services_3.webp') 50% 50%;
            background-size: 100px 70px;
            background-repeat:no-repeat;
          }
          .img_4 {
            background: url('../assets/imgs/aboutus/our_services_4.webp') 50% 50%;
            background-size: 100px 70px;
            background-repeat:no-repeat;
          }
          .img_5 {
            background: url('../assets/imgs/aboutus/our_services_5.webp') 50% 50%;
            background-size: 100px 70px;
            background-repeat:no-repeat;
          }
          .img_6 {
            background: url('../assets/imgs/aboutus/our_services_6.webp') 50% 50%;
            background-size: 100px 70px;
            background-repeat:no-repeat;
          }
          .services_introduction {
            text-align: left;
            width: 60%;
            div:first-child {
              font-size: 36px;
              font-weight: bold;
              color: #161616;
              line-height: 36px;
              margin-bottom: 12px;
            }
            div:last-child {
              font-size: 14px;
              color: #464949;
              line-height: 21px;
            }
          }
        }
        li:nth-child(1):hover > .img_1 {
          background: url('../assets/imgs/aboutus/our_services_1.gif') 50% 50%;
          background-size: 100px 70px;
          background-position: -10px;
          background-repeat: no-repeat;
        }
        li:nth-child(2):hover > .img_2 {
          background: url('../assets/imgs/aboutus/our_services_2.gif') 50% 50%;
          background-size: 100px 70px;
          background-position: -10px;
          background-repeat: no-repeat;
        }
        li:nth-child(3):hover > .img_3 {
          background: url('../assets/imgs/aboutus/our_services_3.gif') 50% 50%;
          background-size: 100px 70px;
          background-position: -10px;
          background-repeat: no-repeat;
        }
        li:nth-child(4):hover > .img_4 {
          background: url('../assets/imgs/aboutus/our_services_4.gif') 50% 50%;
          background-size: 100px 70px;
          background-position: -10px;
          background-repeat: no-repeat;
        }
        li:nth-child(5):hover > .img_5 {
          background: url('../assets/imgs/aboutus/our_services_5.gif') 50% 50%;
          background-size: 100px 70px;
          background-position: -10px;
          background-repeat: no-repeat;
        }
        li:nth-child(6):hover > .img_6 {
          background: url('../assets/imgs/aboutus/our_services_6.gif') 50% 50%;
          background-size: 100px 70px;
          background-position: -10px;
          background-repeat: no-repeat;
        }
      }
    }
  }
}
.fadeinleft {
	-webkit-animation: fade-in-left 1.6s ;
	animation: fade-in-left 1.6s ;
    animation-fill-mode: both;
}
.fadeinleft1 {
	-webkit-animation: fade-in-left 1.6s ;
	animation: fade-in-left 1.6s ;
    animation-delay: 0.5s;
    animation-fill-mode: both;
}
.fadeinright {
	-webkit-animation: fade-in-right 1.6s ;
	animation: fade-in-right 1.6s ;
    animation-fill-mode: both;
}
.fadeinright1 {
	-webkit-animation: fade-in-right 1.6s ;
	animation: fade-in-right 1.6s ;
    animation-delay: 0.5s;
    animation-fill-mode: both;
}
.service_card_in1 {
	-webkit-animation: fade-in-right 1.6s ;
	animation: fade-in-right 1.6s ;
  animation-fill-mode: both;
}
.service_card_in2 {
	-webkit-animation: fade-in-right 1.6s ;
	animation: fade-in-right 1.6s ;
  animation-delay: 0.4s;
  animation-fill-mode: both;
}
@media screen and (max-width: 1160px){
  .about {
    .about_team_container {
      padding: 80px 0 80px;
      .about_team_box  {
        .team_list_box {
          margin-top: 40px;
          flex-wrap: wrap;
          li {
            width: 45%;
            margin-bottom: 40px;
          }
        }
      }
    }
    .our_services_container {
      .our_services_box {
        .serverices_list_box {
          li {
            .services_introduction {
              div:first-child {
                font-size: 30px;
              }
            }
          }
        }
      }
    }
  }
}
@media screen and (max-width: 820px){
  .about {
    .company_description_container {
      .description {
        margin-bottom: 40px;
      }
    }
    .development_plan_container {
      flex-direction: column-reverse;
      width: 100%;
      .left {
        width: 90%;
        text-align: center;
        h3 {
          text-align: center;
        }
        div {
          text-align: center;
          margin-bottom: 40px;
        }
      }
      .right {
        width: 100%;
        margin-bottom: 40px;
      }
    }
    .company_culture_container {
      width: 100%;
      flex-direction: column;
      margin: 40px auto 40px;
      .left {
        width: 100%;
        text-align: center;
        .cultureswp {
          justify-content: center;
        }
      }
      .right {
        width: 90%;
        h3 {
          text-align: center;
        }
        div {
          text-align: center;
        }
      }
    }
  }
}
@media screen and (max-width: 767px){
  .about {
    .about_team_container {
      .about_team_box  {
        .team_list_box {
          li {
            width: 100%;
            margin-bottom: 40px;
            .box {
              @include flexc();
            }
          }
        }
      }
    }
    .our_services_container {
      .our_services_box {
        .serverices_list_box {
          flex-direction: column;
          li {
            width: 90%;
            .serverices_card_img {
              margin-right: 0;
            }
            .services_introduction {
              font-size: 30px;
            }
          }
        }
      }
    }
  }
}
</style>